<template>
  <div class="root">
    <div class="top top-provider-img">
      <img loading="lazy" src="@/assets/pc/banner/casino-bn.jpg" class="provider-banner" alt="">
      <!-- <div class="top-provider-content ">
        <p class="provider-type">{{t('真人娱乐')}}</p>
        <div class="provider-desc">
          <p>{{t('游玩由我们的真人荷官掌牌的游戏')}}</p>
          <p>{{t('今天就注册以享受无尽的奖励')}}</p>
        </div>
      </div> -->
    </div>
    <div class="mid com-container">
      <ul class="provider-box">
        <li v-for="(item,index) in navObj.list" class="tab-header" :class="index == navObj.index ? 'active':''" @click="itemClick(item,index)">
          <div class="tab-inner">
            <img :src="comApi.getFactoryIcon(item.factoryInfo.FactoryName)" alt="">
            <span>{{item.factoryInfo.Name}}</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="standard-tab-container com-container">
      <div class="standard-tab">
        <div class="tab-list">
          <div v-for="(item,index) in navObj.list[navObj.index].gameType" class="tab-header" :class="index == navObj.typeIndex ?'active':'' " @click="typeItemClick(item,index)">{{item.name}}</div>
        </div>
      </div>
    </div>
    <div class="bottom com-container">
      <div class="casino-content-container">
        <div class="item-content">
          <div v-for="(item,index) in navObj.showList" class="item-columns animated fadeInUp">
            <div class="game-img">
              <img :src="comApi.getNormalImg(item.Mthumbnail)" alt="" lazy="loaded">
            </div>
            <div class="btn-container">
              <button aria-label="launch-game" class="game-play-btn" @click="comApi.toFactory(item)">{{t('开始')}}</button>
            </div>
            <div class="bn-desc">
              <p class="name">{{item.GameNameEn}}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="best-live-games-container">
        <div class="live-casino-slider-section">
          <div class="top-container">
            <p class="live-casino-slider-title">最佳真人娱乐游戏</p>
          </div>
          <div>
            <div class="banner-recom banner-recom1">
              <div class="banner-wrapper">
                <swiper :modules="recom1Obj.modules" :autoplay="{ delay: recom1Obj.delay, disableOnInteraction: false }" :loop="true" :circular="true" :grab-cursor="true" :navigation="recom1Obj.navigation" :space-between="13" slides-per-view="auto">
                  <swiper-slide v-for="(item, index) in recom1Obj.list" :key="item">
                    <div class="game-container">
                      <div class="game-img-warpper">
                        <span class="global-placeholder lazy-load-image-background  lazy-load-image-loaded">
                          <img loading="lazy" :src="comApi.getFactoryGameIcon(item)" alt="sLongX3"></span>
                      </div>
                      <div class="game-content">
                        <div class="row-1">
                          <p class="game-name">龙龙龙</p>
                          <div class="icon-container">
                            <div class="new-icon">最新</div>

                          </div>
                        </div>
                        <p class="game-type">Nextspin</p>
                        <p class="game-rtp">回报率 <span>96.87</span></p>
                      </div>
                    </div>
                  </swiper-slide>
                </swiper>
                <div class="swiper-button-prev-recom1">
                  <img src="@/assets/pc/common/back_left.svg" alt="">
                </div>
                <div class="swiper-button-next-recom1">
                  <img src="@/assets/pc/common/back_left.svg" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import comApi from "@/utils/comApi";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination, FreeMode, Navigation } from "swiper";
import "swiper/swiper.css";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
let navList = comApi.getCurNavListByType('LiveCasino');
let navObj = reactive({
  index: 0,
  typeIndex: 0,
  showList: [],
  list: navList,
})
//组合每个厂商下的类型
setTypeInFactory();

if (curRouter.query && curRouter.query.fc) {
  for (let key in navObj.list) {
    let info = navObj.list[key];
    if (info.factoryInfo.FactoryName == curRouter.query.fc) {
      navObj.index = Number(key);
      break;
    }
  }
}

navObj.showList = navObj.list[navObj.index].gameType[navObj.typeIndex].list;


function setTypeInFactory() {
  //setup1 ,自定义游戏类型数组
  for (let key in navObj.list) {
    let info = navObj.list[key];
    info['gameType'] = [];
    info.gameType.push({
      name: 'ALL',
      list: getTypeList(info.gameList)
    })
  }
  //setup2,添加每一个类型的数组
  for (let key in navObj.list) {
    let info = navObj.list[key];
    let findType = {};
    let findTypeArr = [];
    for (let key1 in info.gameType[0].list) {
      let info1 = info.gameType[0].list[key1];
      if (!findType[info1.GameType]) {
        findType[info1.GameType] = [];
      }
      findType[info1.GameType].push(info1);
    }

    for (let key2 in findType) {
      let list2 = findType[key2];//实际为list
      info.gameType.push({
        name: list2[0].GameType,
        list: list2
      });
    }
  }
  log.info('setTypeInFactory', navObj.list);
}

function getTypeList(list) {
  let arr = [];
  for (let key in list) {
    if (list[key].GameType) {
      arr.push(list[key]);
    }
  }
  return arr;
}

function itemClick(item, index) {
  if (navObj.index == index) return;
  navObj.index = index;
  navObj.typeIndex = 0;
  navObj.showList = [];
  nextTick(() => {
    navObj.showList = navObj.list[navObj.index].gameType[navObj.typeIndex].list;
  })
}


function typeItemClick(item, index) {
  if (navObj.typeIndex == index) return;
  navObj.typeIndex = index;
  navObj.showList = [];
  nextTick(() => {
    navObj.showList = navObj.list[navObj.index].gameType[navObj.typeIndex].list;
  })
}

function setSelectIndex() {
  let index = -1;
  if (curRouter.query && curRouter.query.fc) {
    for (let key in navObj.list) {
      let info = navObj.list[key];
      if (info.factoryInfo.FactoryName == curRouter.query.fc) {
        index = Number(key);
        break;
      }
    }
  }
  if (index != -1) {
    itemClick(null, index);
  }

}

watch(() => {
  curRouter.path
}, (newValue, oldValue) => {
  setSelectIndex();
}, { deep: true })

</script>

<style lang="scss" scoped>
.root {
  background: 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .top {
    position: relative;
    margin: auto;
    width: 100%;
    height: 0;
    padding-bottom: 25%;
    img {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .top-provider-content {
      position: absolute;
      bottom: 15%;
      left: 15%;
      color: #000;
      width: 100%;
      word-break: normal;
      overflow-wrap: break-word;
      max-width: 1340px;
      .provider-type {
        font-weight: 600;
        font-size: 55px;
        margin: 0 0 5px;
      }
      .provider-desc {
        width: 27%;
        p:first-child {
          font-size: 30px;
          margin: 0 0 10px;
        }
        p:last-child {
          font-size: 16px;
          margin: 0;
        }
      }
    }
  }
  .mid {
    background: #f3f5f6;
    border-radius: 10px;
    margin: 50px auto;
    padding: 22px 52px;
    .provider-box {
      display: grid;
      flex-wrap: wrap;
      grid-gap: 10px;
      grid-template-columns: repeat(7, 0.7fr);
      .tab-header {
        background: #fff;
        border: 1px solid transparent;
        border-radius: 50px;
        padding: 5px 0;
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        .float-dot {
          border-radius: 4px;
          font-size: 8px;
          padding: 3px 8px;
          position: absolute;
          right: -4px;
          text-align: center;
          top: -5px;
          line-height: normal;
          border-radius: 10px;
          font-family: helvetica neue, helvetica-regular;
        }
        .new-provider {
          background: #e0092c;
          color: #fff;
        }
        .tab-inner {
          align-items: center;
          display: flex;
          justify-content: center;
          opacity: 0.5;
          img {
            filter: grayscale(100%);
            margin: 0 5px 0 0;
            height: 20px;
            width: auto;
            object-fit: contain;
          }
          span {
            color: #8697a2;
            font-size: 14px;
            white-space: nowrap;
          }
        }
      }
      .tab-header:hover,
      .active {
        border: 1px solid #ff8a00;
        .tab-inner {
          opacity: 1;
          img {
            filter: grayscale(0%);
          }
          span {
            color: #0f161f;
          }
        }
      }
    }
  }
  .standard-tab-container {
    margin: 20px auto 30px;
    padding: 0 52px;
    .standard-tab {
      display: flex;
      justify-content: space-between;
      width: 100%;
      display: flex;
      font-size: 14px;
      padding: 0;
      .tab-list {
        display: flex;
        .tab-header {
          border-radius: 50px;
          color: #8697a2;
          background: transparent;
          cursor: pointer;
          height: auto;
          padding: 5px 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: auto;
          white-space: nowrap;
          flex-grow: inherit;
          margin: 0 10px 0 0;
          font-weight: 500;
          position: relative;
        }
        .active {
          background: #ff8a00;
          color: #fff;
        }
      }
    }
  }
  .bottom {
    margin: auto;
    display: flex;
    flex-flow: column;
    .casino-content-container {
      padding: 0 0 50px;
      min-height: 300px;
      .item-content {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        white-space: nowrap;
        grid-gap: 15px;
        margin: 0 0 40px;
        .item-columns {
          position: relative;
          text-align: center;
          .game-img {
            width: 100%;
            padding-bottom: 63.25%;
            height: 0;
            overflow: hidden;
            position: relative;
            border-radius: 10px;
            background: rgba(0, 0, 0, 0.1019607843);
            img {
              position: absolute;
              left: 0;
              right: 0;
              width: 100%;
              height: 100%;
            }
          }
          .btn-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            position: absolute;
            transition: 0.3s;
            transform: translateY(0%);
            opacity: 0;
            visibility: hidden;
            background: rgba(255, 255, 255, 0.8);
            .game-play-btn {
              background: #ff8a00;
              width: 105px;
              height: 30px;
              color: #fff;
              border-radius: 20px;
              font-size: 14px;
            }
            button {
              border: none;
              cursor: pointer;
            }
          }
          .bn-desc {
            align-items: center;
            background: rgba(0, 0, 0, 0.6);
            border-radius: 0 0 10px 10px;
            bottom: 0;
            display: flex;
            justify-content: space-between;
            padding: 10px 20px;
            position: absolute;
            width: 100%;
            .name {
              color: #fff;
              font-size: 14px;
              margin: 0;
            }
          }
        }
        .item-columns:hover {
          .btn-container {
            transform: translateY(-100%);
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
    .best-live-games-container {
      .live-casino-slider-section {
        margin: -40px 0 0;
        .top-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #0f161f;
          margin: 0 0 22px;
          position: relative;
          height: 21px;
          .live-casino-slider-title {
            font-weight: 600;
            font-size: 18px;
            position: absolute;
            left: 0;
          }
        }
        .banner-recom {
          margin-bottom: 32px;
          .banner-wrapper {
            position: relative;
            :deep(.swiper) {
              .swiper-slide {
                width: auto;
                position: relative;
                height: 315px;
                a {
                  position: relative;
                  color: #fff;
                  font-size: 1rem;
                  display: block;
                  width: 350px;
                  line-height: 30px;
                  padding: 5px;
                  .platform-menu-img {
                    position: relative;
                    width: 100%;
                    height: 0;
                    padding-bottom: 36%;
                    img {
                      border-radius: 15px;
                      position: absolute;
                      left: 0;
                      right: 0;
                      width: 100%;
                      height: 100%;
                    }
                  }
                }
                .game-container {
                  position: relative;
                  .game-img-warpper {
                    position: relative;
                    width: 230px;
                    height: 155px;
                    border-radius: 10px;
                    overflow: hidden;
                    .global-placeholder {
                      display: inline-block;
                      width: 100%;
                      height: 100%;
                      background: rgba(0, 0, 0, 0.1019607843);
                      overflow: hidden;
                      img {
                        width: 100%;
                        height: 100%;
                        cursor: pointer;
                        transition: opacity 0.2s ease-in;
                      }
                    }
                    .btn-container {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      flex-direction: column;
                      width: 230px;
                      height: 160px;
                      position: absolute;
                      transition: 0.3s;
                      transform: translateY(0%);
                      opacity: 0;
                      visibility: hidden;
                      background: rgba(255, 255, 255, 0.8);
                    }
                  }
                  .game-content {
                    margin: 13px 0 0;
                    .row-1 {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      height: 16px;
                      .game-name {
                        color: #0f161f;
                        font-size: 15px;
                        margin: 0 0 0 9px;
                        width: 150px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      }
                      .icon-container {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        font-size: 12px;
                        .hot-icon {
                          background: #ff650f;
                          padding: 4px 6px;
                          border-radius: 0.2rem;
                          text-align: center;
                          color: #fff;
                        }
                      }
                    }
                    .game-type {
                      color: #8697a2;
                      font-size: 13px;
                      margin: 3px 0 0 9px;
                    }
                    .game-rtp {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      width: fit-content;
                      border-radius: 8px;
                      background: #0088f3;
                      font-size: 13px;
                      margin: 3px 0 0;
                      color: #fff;
                      padding: 4px 10px;
                      span {
                        margin: 0 0 0 15px;
                      }
                    }
                  }
                }
              }
              .swiper-pagination {
                bottom: -25px;
                left: 0;
                width: 100%;
                display: flex;
                justify-content: center;
                .swiper-pagination-bullet {
                  margin: 0 4px;
                  display: inline-block;
                  padding: 0;
                  position: relative;
                  cursor: pointer;
                  width: 35px;
                  height: 5px;
                  background: rgba(233, 132, 126, 0.5);
                  border-radius: 10px;
                  color: transparent;
                  opacity: 0.5;
                }

                .swiper-pagination-bullet-active {
                  background: #e9847e;
                  opacity: 1;
                }
              }
            }
          }
          .swiper-button-prev-recom1,
          .swiper-button-next-recom1 {
            width: 28px;
            height: 28px;
            background: #dbe9f0;
            border-radius: 50%;
            overflow: visible;
            fill: #6da0dd;
            position: absolute;
            cursor: pointer;
            z-index: 10;
            top: 50%;
            transform: translateY(-50%);
            img {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 7px;
            }
          }
          .swiper-button-prev-recom1 {
            left: -13px;
            top: 75px;
          }
          .swiper-button-next-recom1 {
            right: -13px;
            top: 75px;
            img {
              transform: translate(-50%, -50%) rotate(180deg);
            }
          }
        }
      }
    }
  }
}
</style>