<template>
  <div class="home">
    <div class="banner-top">
      <div class="banner-wrapper">
        <swiper :modules="bannerObj.modules" :autoplay="{ delay: bannerObj.delay, disableOnInteraction: false }" :speed="1000" :loop="true" :circular="true" :grab-cursor="true" :pagination="{ clickable: true }" slides-per-view="auto">
          <swiper-slide v-for="(item, index) in bannerObj.list" :key="item">
            <a>
              <div class="platform-menu-img">
                <img :src="comApi.getNormalImg(tools.getCurLangValue(item.icon))" alt="" @click="comApi.eventJumpByType(item)">
              </div>
            </a>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="home-bg-container">
      <div class="com-container home-content-wrapper">
        <div class="banner-promo">
          <div class="banner-wrapper">
            <swiper :modules="activeObj.modules" :autoplay="{ delay: activeObj.delay, disableOnInteraction: false }" :loop="true" :circular="true" :grab-cursor="true" :navigation="activeObj.navigation" slides-per-view="auto">
              <swiper-slide v-for="(item, index) in activeObj.list" :key="item" @click="toInfo(item)">
                <a>
                  <div class="platform-menu-img">
                    <img :src="getBannerImg(item)" alt="">
                  </div>
                </a>
              </swiper-slide>
            </swiper>
            <div class="swiper-button-prev-promo">
              <img src="@/assets/pc/common/back_left.svg" alt="">
            </div>
            <div class="swiper-button-next-promo">
              <img src="@/assets/pc/common/back_left.svg" alt="">
            </div>
          </div>
        </div>
        <!-- <div class="announcementBarSection">
          <div class="annoucement-bar-component com-container">
            <div class="annoucement-moving announcement-content-container">
              <div :key="noticeObj.key" class="announcement-content-animation-box msg-enter">
                <div class="left-block">
                  {{t('新闻')}}
                </div>
                <span class="left-content">{{noticeObj.list[noticeObj.index]}}
                </span>
              </div>
            </div>
          </div>
        </div> -->
        <div class="hotmatch-section">
          <div class="top-container">
            <p class="hotmatch-title">{{t('即将举行的直播赛事')}}</p>
            <div class="hotmatch-type-container">
              <button v-for="(item,index) in ballObj.list" class="type-btn" :class="ballObj.active == index ? 'active' :'' " @click="ballItemClick(index)">{{t(item.lable)}}</button>
            </div>
          </div>
          <div class="betting-hotmatch-container">
            <div class="betting-partner-bg">
              <button v-if="ballObj.list[ballObj.active].list.length > 0" class="btnBetNow" @click="mactchClick()">{{t('现在投注')}}</button>
              <img src="@/assets/pc/down/videoImage.png" alt="">
            </div>
            <div v-if="ballObj.list[ballObj.active].list.length > 0" class="betting-swiper">
              <div class="banner-wrapper">
                <swiper :modules="ballObj.modules" :autoplay="{ delay: ballObj.delay, disableOnInteraction: false }" :grab-cursor="true" :navigation="ballObj.navigation" :slides-per-group="3" :space-between="13" :slides-per-view="3.2" :speed="2000">
                  <swiper-slide v-for="(item, index) in ballObj.list[ballObj.active].list" :key="item">
                    <div>
                      <div class="betContainer">
                        <div class="container-header">
                          <p class="bet-title">{{item.LeagueName}}</p>
                          <div class="header-left">
                            <p class="match-time">{{item.MatchDate}}</p>
                          </div>
                        </div>
                        <ul class="imgWarpper">
                          <li class="team-name">
                            <div class="team-home">
                              <p class="home-type">H</p>
                              <p class="team-name">{{item.HomeTeamName}}</p>
                            </div>
                            <div class="team-away">
                              <p class="away-type">A</p>
                              <p class="team-name">{{item.AwayTeamName}}</p>
                            </div>
                          </li>
                          <li class="team-img">
                            <img loading="lazy" :src="item.HomeImgUrl" class="img-home" alt="">
                            <img loading="lazy" :src="item.AwayImgUrl" class="img-away" alt="">
                          </li>
                          <li class="match-detail">
                            <div class="handicap">
                              <p class="score">{{item.HdpOddsValueAwayFt}}</p>
                              <p>{{t('盘口')}}</p>
                              <p class="score">{{item.HdpOddsValueHomeFt}}</p>
                            </div>
                            <div class="odds">
                              <p class="score">{{item.OuOddsValueAwayFt}}</p>
                              <p>{{t('赔率')}}</p>
                              <p class="score">{{item.OuOddsValueHomeFt}}</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </swiper-slide>
                </swiper>
                <div class="swiper-button-prev-betting">
                  <img src="@/assets/pc/common/back_left.svg" alt="">
                </div>
                <div class="swiper-button-next-betting">
                  <img src="@/assets/pc/common/back_left.svg" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="register-guide-section">
          <p class="guide-title">{{t('如何注册')}}</p>
          <p class="guide-title2">beteasy88{{t('欢迎来到')}}</p>
          <div class="step-container com-container">
            <div class="guide-box box-0">
              <div class="guide-content ">
                <img src="@/assets/pc/home/guide_box_0.svg" alt="">
                <p>{{t( '点击 “立即加入”')}}</p>
                <p>{{t('填写您的登录信息')}}</p>
              </div>
            </div>
            <div class="guide-box box-1">
              <img src="@/assets/pc/home/guide_box_1.svg" alt="">
              <div class="guide-content">{{t('使用货币或加密货币转账进行首次存款')}}</div>
            </div>
            <div class="guide-box box-2">
              <img src="@/assets/pc/home/guide_box_2.svg" alt="">
              <div class="guide-content">{{t('开始在您喜欢的游戏中赢取奖金')}}</div>
            </div>
            <div class="guide-box box-3">
              <img src="@/assets/pc/home/guide_box_3.svg" alt="">
              <div class="guide-content">{{t('不要忘记索取您的红利')}}</div>
            </div>
          </div>
          <img loading="lazy" src="@/assets/pc/home/gradient-bar.jpg" class="com-container progress-bar-img" alt="">
          <div class="step-title-container">
            <div class="step-title title-0">{{t('创建账号')}}</div>
            <div class="step-title title-1">{{t('进行存款')}}</div>
            <div class="step-title title-2">{{t('开始赢钱')}}</div>
            <div class="step-title title-3">{{t('领取奖品')}}</div>
          </div>
        </div>
        <div class="games-section recom1-section">
          <div class="top-container">
            <p class="hot-games-title">{{tools.getCurLangValue(recom1Obj.data.Text)}}</p>
            <p class="see-all-btn" @click="toRouter('/slot')">{{t('查看全部')}}</p>
          </div>
          <div>
            <div class="banner-recom banner-recom1">
              <div class="banner-wrapper">
                <swiper :modules="recom1Obj.modules" :autoplay="{ delay: recom1Obj.delay, disableOnInteraction: false }" :grab-cursor="true" :navigation="recom1Obj.navigation" :space-between="13" slides-per-view="auto">
                  <swiper-slide v-for="(item, index) in recom1Obj.list" :key="item" @click="comApi.toFactory(item.GameDetail ? item.GameDetail:item)">
                    <div class="game-container">
                      <div class="game-img-wrapper">
                        <span class="global-placeholder">
                          <img loading="lazy" :src="comApi.getFactoryGameIcon(item.GameDetail ? item.GameDetail:item,3)">
                        </span>
                        <div class="btn-container">
                          <button aria-label="launch-game" class="game-play-btn">{{t('开始')}}</button>
                        </div>
                      </div>
                      <div class="game-content">
                        <div class="row-1">
                          <p class="game-name">{{ item.GameDetail ? item.GameDetail.GameNameEn:comApi.getFactoryShowName( item.Factory )}}</p>
                          <div class="icon-container">
                            <div class="hot-icon">{{t('热门')}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </swiper-slide>
                </swiper>
                <div class="swiper-button-prev-recom1">
                  <img src="@/assets/pc/common/back_left.svg" alt="">
                </div>
                <div class="swiper-button-next-recom1">
                  <img src="@/assets/pc/common/back_left.svg" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="games-section recom2-section">
          <div class="top-container">
            <p class="hot-games-title">{{tools.getCurLangValue(recom2Obj.data.Text)}}</p>
            <p class="see-all-btn" @click="toRouter('/casino')">{{t('查看全部')}}</p>
          </div>
          <div>
            <div class="banner-recom banner-recom2">
              <div class="banner-wrapper">
                <swiper :modules="recom2Obj.modules" :autoplay="{ delay: recom2Obj.delay, disableOnInteraction: false }" :loop="true" :circular="true" :grab-cursor="true" :navigation="recom2Obj.navigation" :space-between="13" slides-per-view="auto">
                  <swiper-slide v-for="(item, index) in recom2Obj.list" :key="item" @click="comApi.toFactory(item.GameDetail ? item.GameDetail:item)">
                    <div class="game-container">
                      <div class="game-img-wrapper">
                        <span class="global-placeholder lazy-load-image-background  lazy-load-image-loaded">
                          <img loading="lazy" :src="comApi.getFactoryGameIcon(item.GameDetail ? item.GameDetail:item,3)">
                        </span>
                        <div class="btn-container">
                          <button aria-label="launch-game" class="game-play-btn">{{t('开始')}}</button>
                        </div>
                      </div>
                      <div class="game-content">
                        <div class="row-1">
                          <p class="game-name">{{ item.GameDetail ? item.GameDetail.GameNameEn:comApi.getFactoryShowName( item.Factory )}}</p>
                          <div class="icon-container">
                          </div>
                        </div>
                      </div>
                    </div>
                  </swiper-slide>
                </swiper>
                <div class="swiper-button-prev-recom2">
                  <img src="@/assets/pc/common/back_left.svg" alt="">
                </div>
                <div class="swiper-button-next-recom2">
                  <img src="@/assets/pc/common/back_left.svg" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="games-section recom3-section">
          <div class="top-container">
            <p class="hot-games-title">{{tools.getCurLangValue(recom3Obj.data.Text)}}</p>
            <p class="see-all-btn" @click="toRouter('/slot')">{{t('查看全部')}}</p>
          </div>
          <div>
            <div class="banner-recom banner-recom3">
              <div class="banner-wrapper">
                <swiper :modules="recom3Obj.modules" :autoplay="{ delay: recom3Obj.delay, disableOnInteraction: false }" :grab-cursor="true" :navigation="recom3Obj.navigation" :space-between="13" slides-per-view="auto">
                  <swiper-slide v-for="(item, index) in recom3Obj.list" :key="item" @click="comApi.toFactory(item.GameDetail ? item.GameDetail:item)">
                    <div class="game-container">
                      <div class="game-img-wrapper">
                        <span class="global-placeholder">
                          <img loading="lazy" :src="comApi.getFactoryGameIcon(item.GameDetail ? item.GameDetail:item,3)">
                        </span>
                        <div class="btn-container">
                          <button aria-label="launch-game" class="game-play-btn">{{t('开始')}}</button>
                        </div>
                      </div>
                      <div class="game-content">
                        <div class="row-1">
                          <p class="game-name">{{ item.GameDetail ? item.GameDetail.GameNameEn:comApi.getFactoryShowName( item.Factory )}}</p>
                          <div class="icon-container">
                            <div class="new-icon">{{t('最新')}}</div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </swiper-slide>
                </swiper>
                <div class="swiper-button-prev-recom3">
                  <img src="@/assets/pc/common/back_left.svg" alt="">
                </div>
                <div class="swiper-button-next-recom3">
                  <img src="@/assets/pc/common/back_left.svg" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="app-landing-section">
          <p class="app-landing-title">{{t('应用下载')}}</p>
          <Download class="back-color" />
        </div>
        <div class="our-partner-section">
          <div class="top-container">
            <p class="our-partner-title">{{t('我们的合作伙伴')}}</p>
            <p class="see-all-btn">{{t('查看全部')}}</p>
          </div>
          <div class="our-partner-swiper">
            <swiper :modules="factoryObj.modules" :loop="true" :circular="true" :autoplay="{ delay: factoryObj.delay, disableOnInteraction: false, stopOnLastSlide: false, }" :slides-per-view="8" :speed="2000" :space-between="13">
              <swiper-slide v-for="(item, index) in factoryObj.list">
                <div>
                  <img :src="comApi.getNormalImg(item.Icon)" :alt="item.Name" lazy="loaded">
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import comApi from "@/utils/comApi";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination, FreeMode, Navigation } from "swiper";
import "swiper/swiper.css";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import { useI18n } from "vue-i18n";
import Download from "@/views/pc/components/Download.vue"


const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);

//轮播图
let bannerObj = reactive({
  list: getBannerList(),
  modules: [Autoplay, Pagination],
  delay: 3000,
})

//活动图
let activeObj = reactive({
  list: getActiveList(),
  modules: [Autoplay, Pagination, Navigation],
  delay: 5000,
  navigation: {
    nextEl: ".swiper-button-next-promo",
    prevEl: ".swiper-button-prev-promo",
  },
})

//公告
let noticeObj = reactive({
  index: 0,
  key: 0,
  timeFuc: null,
  list: ['a', 'b'],
})

//体育赛事
const ballObj = reactive({
  active: 0,
  modules: [Autoplay, Pagination, Navigation],
  delay: 5000,
  navigation: {
    nextEl: ".swiper-button-next-betting",
    prevEl: ".swiper-button-prev-betting",
  },
  list: [
    {
      lable: '足球',
      list: []
    },
    // {
    //   lable: '篮球',
    //   list: []
    // },
    // {
    //   lable: '棒球',
    //   list: []
    // }
  ]
})
setDataByType();


//推荐1
let [recom1Data, recom1AllList] = getDataListByType('HotLeft');
const recom1Obj = reactive({
  total: recom1AllList.length,
  data: recom1Data,
  list: getLimitList(recom1AllList, 5),
  modules: [Pagination, Navigation],
  delay: 5000,
  navigation: {
    nextEl: ".swiper-button-next-recom1",
    prevEl: ".swiper-button-prev-recom1",
  },
})

//推荐2
let [recom2Data, recom2AllList] = getDataListByType('HotRight');
const recom2Obj = reactive({
  total: recom2AllList.length,
  data: recom2Data,
  list: getLimitList(recom2AllList, 5),
  modules: [Pagination, Navigation],
  delay: 5000,
  navigation: {
    nextEl: ".swiper-button-next-recom2",
    prevEl: ".swiper-button-prev-recom2",
  },
})

//推荐3
let [recom3Data, recom3AllList] = getDataListByType('HotRecommend');
const recom3Obj = reactive({
  total: recom3AllList.length,
  data: recom3Data,
  list: getLimitList(recom3AllList, 5),
  modules: [Pagination, Navigation],
  delay: 5000,
  navigation: {
    nextEl: ".swiper-button-next-recom3",
    prevEl: ".swiper-button-prev-recom3",
  },
})

//厂商列表
let factoryObj = reactive({
  list: getFactoryList(),
  modules: [Autoplay, Pagination, Navigation],
  delay: 1000,
})


onMounted(() => {
  startNoticeTime();
})

onBeforeUnmount(() => {
  stopNoticeTime();
})

function ballItemClick(index) {
  ballObj.active = index;
}

function mactchClick() {
  let name = "apiCmd"
  let url = getApiLoginUrl(name, "");
  $act.openPage(url);
}

function setDataByType() {
  for (let key in lobbyInfo.value.HotMatch) {
    let info = lobbyInfo.value.HotMatch[key];
    ballObj.list[0].list.push(info);
    // if (info.SportType == "S") {
    //   //体育
    //   ballObj.list[0].list.push(info);
    // } else if (info.SportType == "BB") {
    //   //篮球
    //   ballObj.list[1].list.push(info);
    // } else if (info.SportType == "ES") {
    //   ballObj.list[2].list.push(info);
    // }
  }
  log.info('ballObj.list', ballObj)
}

function getBannerList() {
  let arr = [];
  for (let key in lobbyInfo.value.MainCustomContent) {
    let info = lobbyInfo.value.MainCustomContent[key]
    if (info.type == 'banner') {
      let nowTime = Date.now();
      let startTime = Date.parse(info.time[0]);
      let endTime = Date.parse(info.time[1]);
      if (nowTime > startTime && nowTime < endTime) {
        if (tools.isMobile()) {
          if (info.platformType !== 'pc' && info.status) {
            arr.push(info);
          }
        } else {
          if (info.platformType == 'pc' && info.status) {
            arr.push(info);
          }
        }
      }
    }
  }
  arr.sort((a, b) => {
    return b.sort - a.sort;
  })
  log.info('bannerList---', arr);
  return arr;
}

function getActiveList() {
  let arr = [];
  for (let key in lobbyInfo.value.MainCustomContent) {
    let info = lobbyInfo.value.MainCustomContent[key];
    if (info.type == 'activityArtical' && info.status) {
      let nowTime = Date.now();
      let startTime = Date.parse(info.openTime[0].replace(/-/g, '/'));
      let endTime = Date.parse(info.openTime[1].replace(/-/g, '/'));
      if (nowTime > startTime && nowTime < endTime) {
        arr.push(info);
      }
    }
  }
  arr.sort((a, b) => {
    return Number(a.sort) - Number(b.sort);
  })
  return arr;
}

function toInfo(item) {
  bus.emit(bus.event.promotionPop, { bool: true, data: item })
}

function getBannerImg(item) {
  if (tools.isMobile()) {
    return comApi.getNormalImg(tools.getCurLangValue(item.iconMobile));
  } else {
    return comApi.getNormalImg(tools.getCurLangValue(item.icon));
  }
}

function startNoticeTime() {
  if (noticeObj.list.length > 1) {
    noticeObj.timeFuc = setInterval(() => {
      noticeObj.index++;
      noticeObj.key++;
      if (noticeObj.index >= noticeObj.list.length) {
        noticeObj.index = 0;
      }
    }, 3000)
  }
}

function stopNoticeTime() {
  noticeObj.timeFuc && clearTimeout(noticeObj.timeFuc)
}

function getDataListByType(navigate) {
  let allRecommend = lobbyInfo.value.AllRecommend;
  let curObj = allRecommend.labels.find(info => info.Navigate == navigate);
  if (!curObj) return [{}, []];
  let arr = [];
  for (let key in allRecommend.all) {
    if (navigate == key) {
      arr = allRecommend.all[key];
      break;
    }
  }
  let newArr = [];
  for (let key in arr) {
    if (arr[key].GameDetail) {
      if (arr[key].GameDetail.Status != 4) {
        newArr.push(arr[key])
      }
    } else {
      if (arr[key].Status != -1) {
        newArr.push(arr[key])
      }
    }
  }
  return [curObj, newArr];
}

function getLimitList(list, num) {
  return list.slice(0);
}

function getFactoryList() {
  let arr = [];
  for (let key in lobbyInfo.value.FactoryList) {
    if (lobbyInfo.value.FactoryList[key].Status == 1) {
      arr.push(lobbyInfo.value.FactoryList[key])
    }
  }
  log.info('getFactoryList', arr);
  return arr;
}


function getMaintainState(item) {
  if (item.GameDetail) {
    //是子游戏
    if (item.Status == 2) {
      return true
    }
    if (item.GameDetail.Status == 3) {
      return true
    }
  } else {
    if (item.Status == 2) {
      return true
    }
  }
  return false;
}

function toRouter(path) {
  router.push({
    path: path
  }
  )
}
function toRegister() {
  bus.emit(bus.event.resgisterPop, true);
}

function toLoginPop() {
  bus.emit(bus.event.loginState, true);
}

</script>

<style lang="scss" scoped>
.home {
  .banner-top {
    overflow: hidden;
    .banner-wrapper {
      overflow: hidden;
      :deep(.swiper) {
        .swiper-slide {
          position: relative;
          a {
            position: relative;
            color: #fff;
            font-size: 1rem;
            display: block;
            .platform-menu-img {
              position: relative;
              width: 100%;
              height: 0;
              padding-bottom: 25%;
              img {
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
        .swiper-pagination {
          position: absolute;
          bottom: 16px;
          left: 0%;
          margin: 0 49px;
          text-align: left;
          .swiper-pagination-bullet {
            background: #fff;
            width: 9px;
            height: 9px;
            transform: unset;
            margin: 0 5px;
            border-radius: 50%;
          }

          .swiper-pagination-bullet-active {
            background: #fdb813;
            width: 29px;
            height: 9px;
            transform: unset;
            margin: 0 10px;
            border-radius: 10px;
          }
        }
      }
    }
  }
  .home-bg-container {
    .home-content-wrapper {
      margin: auto;
      padding: 38px 0 43px;
      border-bottom: 1px solid;
      border-image: linear-gradient(to right, #dde0e0 80%, #fafafa 100%) 1;
      .banner-promo {
        margin-bottom: 32px;
        .banner-wrapper {
          position: relative;
          :deep(.swiper) {
            .swiper-slide {
              width: auto;
              position: relative;
              .swiper-slide:first-child {
                margin-left: 0;
                padding-left: 0;
              }
              a {
                position: relative;
                color: #fff;
                font-size: 1rem;
                display: block;
                width: 350px;
                line-height: 30px;
                padding: 5px;
                .platform-menu-img {
                  position: relative;
                  width: 100%;
                  height: 0;
                  padding-bottom: 36%;
                  img {
                    border-radius: 15px;
                    position: absolute;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
            .swiper-pagination {
              bottom: -25px;
              left: 0;
              width: 100%;
              display: flex;
              justify-content: center;
              .swiper-pagination-bullet {
                margin: 0 4px;
                display: inline-block;
                padding: 0;
                position: relative;
                cursor: pointer;
                width: 35px;
                height: 5px;
                background: rgba(233, 132, 126, 0.5);
                border-radius: 10px;
                color: transparent;
                opacity: 0.5;
              }

              .swiper-pagination-bullet-active {
                background: #e9847e;
                opacity: 1;
              }
            }
          }
        }
      }
      .announcementBarSection {
        width: 100%;
        background: transparent;
        margin: 30px auto 0;
        position: inherit;
        display: block;
        justify-content: unset;
        .annoucement-bar-component {
          color: #0f161f;
          font-size: 14px;
          cursor: pointer;
          height: unset;
          display: grid;
          margin: auto;
          align-items: center;
          position: relative;
          overflow: hidden;
          grid-template-columns: 90% 10%;
          .announcement-content-container {
            justify-content: center;
            overflow: hidden;
            position: relative;
            display: flex;
            white-space: nowrap;
            z-index: 1;
            height: 25px;
            align-items: center;
            margin: unset;
          }
          .msg-enter {
            transform: translateY(100%);
            opacity: 0;
          }
          .announcement-content-animation-box {
            display: flex;
            align-items: center;
            animation-name: animationFade;
            animation-duration: 2s;
            animation-fill-mode: both;
          }
          @keyframes animationFade {
            0% {
              opacity: 0;
              transform: translateY(100%);
            }
            50% {
              opacity: 1;
              transform: translateY(0%);
            }
            100% {
              opacity: 0;
              transform: translateY(-100%);
            }
          }
          .left-block {
            min-width: fit-content;
            width: unset;
            height: 19px;
            padding: 0 10px;
            margin: 0 16px 0 0;
            color: #fff;
            font-size: 12px;
            border-radius: 3px;
            justify-content: center;
            align-items: center;
            background: #0c4da2;
            display: flex;
          }
          .left-content {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .hotmatch-section {
        margin: 60px 0 0;
        .top-container {
          display: flex;
          align-items: center;
          margin: 0 0 18px;
          .hotmatch-title {
            color: #0f161f;
            font-weight: 600;
            font-size: 18px;
            margin: 0 40px 0 0;
            white-space: nowrap;
          }
          .hotmatch-type-container {
            display: flex;
            align-items: center;
            .type-btn {
              font-size: 14px;
              color: #8697a2;
              background: rgba(0, 0, 0, 0);
              padding: 6px 20px;
              border-radius: 20px;
              border: none;
              cursor: pointer;
              white-space: nowrap;
            }
            .type-btn:not(:last-child) {
              margin: 0 17px 0 0;
            }
            .active {
              color: #fff;
              background: #ff8a00;
            }
          }
        }
        .betting-hotmatch-container {
          background: #f3f5f6;
          padding: 10px;
          border-radius: 15px;
          width: 100%;
          display: flex;
          flex-direction: column;
          .betting-partner-section {
            border-radius: 10px;
            position: relative;
            overflow: hidden;
            // background: url("@/assets/pc/down/videoImage.png") no-repeat;
            // background-size: 100%;
            .btnBetNow:hover {
              background: #ff7200;
            }
          }
          .betting-partner-bg {
            position: relative;
            .btnBetNow {
              position: absolute;
              left: 50px;
              top: 50px;
              background: #ff8a00;
              padding: 6px 24px 4px;
              border-radius: 20px;
              border: none;
              cursor: pointer;
              color: #fff;
              font-size: 14px;
            }
            img {
              width: 100%;
              height: 400px;
              border-radius: 20px;
            }
          }
          .betting-swiper {
            margin: 10px 0 0;
            .banner-wrapper {
              position: relative;
              :deep(.swiper) {
                .swiper-slide {
                  position: relative;
                  .betContainer {
                    width: 100%;
                    display: inline-block;
                    position: relative;
                    background: #fff;
                    border-radius: 5px;
                    overflow: hidden;
                    .container-header {
                      display: grid;
                      grid-template-columns: 60% 40%;
                      background: linear-gradient(to right, #48125e, #73277f);
                      padding: 10px 10px 10px 16px;
                      font-size: 14px;
                      .bet-title {
                        color: #fff;
                        overflow: hidden;
                        margin: 0;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        font-size: 15px;
                      }
                      .header-left {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        .match-time {
                          margin: 0 8px 0 0;
                          color: #fff;
                          white-space: nowrap;
                        }
                      }
                    }
                    .imgWarpper {
                      display: grid;
                      justify-content: space-between;
                      padding: 12px 24px;
                      grid-template-areas: "teamName teamName teamImg teamImg" "matchDetail matchDetail matchDetail matchDetail";
                      grid-template-columns: 120px 100px 100px;
                      color: #0f161f;
                      .team-name {
                        grid-area: teamName;
                        font-size: 16px;
                        .team-home {
                          margin: 0 0 10px;
                          display: flex;
                          align-items: center;
                          .home-type {
                            background: #33c600;
                            margin: 0 10px 0 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 18px;
                            height: 18px;
                            border-radius: 50%;
                            color: #fff;
                            font-size: 11px;
                          }
                          .team-name {
                            color: #0f161f;
                            margin: 0;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            font-size: 16px;
                          }
                        }
                        .team-away {
                          display: flex;
                          align-items: center;
                          .away-type {
                            background: #ff4e00;
                            margin: 0 10px 0 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 18px;
                            height: 18px;
                            border-radius: 50%;
                            color: #fff;
                            font-size: 11px;
                          }
                          .team-name {
                            color: #0f161f;
                            margin: 0;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            font-size: 16px;
                          }
                        }
                      }
                      .team-img {
                        grid-area: teamImg;
                        position: relative;
                        img {
                          position: absolute;
                          width: 50px;
                          height: 50px;
                          filter: drop-shadow;
                          box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.3);
                          border-radius: 50%;
                          background: #fff;
                        }
                        .img-home {
                          left: 8%;
                          top: -10%;
                          z-index: 2;
                        }
                        .img-away {
                          right: 8%;
                          bottom: -10%;
                          z-index: 1;
                        }
                      }
                      .match-detail {
                        grid-area: matchDetail;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin: 18px 0 0;
                        font-size: 15px;
                        > div {
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                          padding: 5px 15px;
                          width: 49%;
                          background: #f3f5f6;
                          border-radius: 50px;
                          p:nth-child(2) {
                            color: #8697a2;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .register-guide-section {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 0 104px;
        .guide-title {
          color: #0f161f;
          font-size: 18px;
          margin: 105px 0 0;
        }
        .guide-title2 {
          color: #0f161f;
          font-size: 36px;
          margin: 6px 0 0;
        }
        .step-container {
          display: grid;
          grid-template-columns: 23% 23% 23% 23%;
          margin: 50px 0 0;
          width: 1200px;
          justify-content: space-between;
          text-align: center;
          .guide-box {
            padding: 15px;
            border-radius: 10px;
            img {
              width: 75px;
              height: 75px;
            }
            .guide-content {
              margin: 15px 5px 5px;
              word-break: break-word;
              p {
                margin: 0;
                font-size: 16px;
                color: #0f161f;
              }
            }
          }
          .guide-box.box-0 {
            border: 1px solid red;
          }
          .guide-box.box-1 {
            border: 1px solid #67e49e;
          }
          .guide-box.box-2 {
            border: 1px solid #f7921a;
          }
          .guide-box.box-3 {
            border: 1px solid #fbb813;
          }
        }
        .progress-bar-img {
          width: 1200px;
          margin: 16px 0 0;
        }
        .step-title-container {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 18px 0 0;
          .step-title {
            width: 276px;
            text-align: center;
            font-size: 20px;
          }
          .step-title:not(:last-child) {
            margin: 0 32px 0 0;
          }
          .title-0 {
            color: red;
          }
          .title-1 {
            color: #50c583;
          }
          .title-2 {
            color: #f7921a;
          }
          .title-3 {
            color: #f9b100;
          }
        }
      }
      .banner-recom {
        margin-bottom: 32px;
        .banner-wrapper {
          position: relative;
          :deep(.swiper) {
            .swiper-slide {
              width: calc(100% / 7);
              position: relative;
              .game-container {
                position: relative;
                .game-img-wrapper {
                  position: relative;
                  border-radius: 10px;
                  overflow: hidden;
                  .global-placeholder {
                    position: relative;
                    display: inline-block;
                    width: 100%;
                    width: 100%;
                    padding-bottom: 100%;
                    background: rgba(0, 0, 0, 0.1019607843);
                    overflow: hidden;
                    img {
                      position: absolute;
                      left: 0;
                      right: 0;
                      width: 100%;
                      height: 100%;
                      cursor: pointer;
                      object-fit: cover;
                      transition: opacity 0.2s ease-in;
                    }
                  }
                  .btn-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    position: absolute;
                    transition: 0.3s;
                    transform: translateY(0%);
                    opacity: 0;
                    visibility: hidden;
                    background: rgba(255, 255, 255, 0.8);
                    .game-play-btn {
                      background: #ff8a00;
                      width: 105px;
                      height: 30px;
                      color: #fff;
                      border-radius: 20px;
                      font-size: 14px;
                    }
                    button {
                      border: none;
                      cursor: pointer;
                    }
                  }
                }
                .game-img-wrapper:hover {
                  .btn-container {
                    transform: translateY(-100%);
                    visibility: visible;
                    opacity: 1;
                  }
                }
                .game-content {
                  margin: 5px 0 0;
                  .row-1 {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 26px;
                    .game-name {
                      color: #0f161f;
                      font-size: 15px;
                      margin: 0 0 0 9px;
                      width: 150px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }
                    .icon-container {
                      display: flex;
                      align-items: center;
                      gap: 5px;
                      font-size: 12px;
                      .hot-icon {
                        background: #ff650f;
                        padding: 4px 6px;
                        border-radius: 10px;
                        text-align: center;
                        color: #fff;
                        line-height: normal;
                        white-space: nowrap;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      }
                      .new-icon {
                        background: #e0092c;
                        padding: 4px 6px;
                        border-radius: 10px;
                        text-align: center;
                        color: #fff;
                        line-height: normal;
                        white-space: nowrap;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      }
                    }
                  }
                  .game-type {
                    color: #8697a2;
                    font-size: 13px;
                    margin: 3px 0 0 9px;
                  }
                  .game-rtp {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: fit-content;
                    border-radius: 8px;
                    background: #0088f3;
                    font-size: 13px;
                    margin: 3px 0 0;
                    color: #fff;
                    padding: 4px 10px;
                    span {
                      margin: 0 0 0 15px;
                    }
                  }
                }
              }
            }
            .swiper-pagination {
              bottom: -25px;
              left: 0;
              width: 100%;
              display: flex;
              justify-content: center;
              .swiper-pagination-bullet {
                margin: 0 4px;
                display: inline-block;
                padding: 0;
                position: relative;
                cursor: pointer;
                width: 35px;
                height: 5px;
                background: rgba(233, 132, 126, 0.5);
                border-radius: 10px;
                color: transparent;
                opacity: 0.5;
              }

              .swiper-pagination-bullet-active {
                background: #e9847e;
                opacity: 1;
              }
            }
          }
        }
      }
      .games-section {
        position: relative;
        .top-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #0f161f;
          margin: 0 0 22px;
          .hot-games-title {
            font-weight: 600;
            font-size: 18px;
          }
          .see-all-btn {
            font-size: 16px;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      .recom1-section,
      .recom3-section {
        .global-placeholder {
          padding-bottom: 145% !important;
        }
      }
      .recom2-section {
        margin: 50px 0;
        .global-placeholder {
          padding-bottom: 60% !important;
        }
      }
      .recom3-section {
        margin: 50px 0;
      }
      .app-landing-section {
        margin-top: 50px !important;
        .app-landing-title {
          font-weight: 600;
          font-size: 18px;
          color: #0f161f;
        }
        .back-color {
          background-color: #fff !important;
        }
      }
      .our-partner-section {
        margin: 50px 0 0;
        .top-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #0f161f;
          margin: 0 0 17px;
          .our-partner-title {
            font-weight: 600;
            font-size: 18px;
          }
          .see-all-btn {
            font-size: 16px;
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .our-partner-swiper {
          position: relative;
          height: 133px;
          background: #f3f5f6;
          border-radius: 10px;
          padding: 0 10px;
          :deep(.swiper) {
            height: 100%;
            .swiper-wrapper {
              position: relative;
              display: flex;
              align-items: center;
              .swiper-slide {
                height: 80px;
                background: #fff;
                border-radius: 10px;
                > div {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 100%;
                  img {
                    width: 80% !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .swiper-button-disabled {
    opacity: 0.2;
    cursor: not-allowed;
    pointer-events: auto;
  }
  .swiper-button-prev-promo,
  .swiper-button-next-promo,
  .swiper-button-prev-betting,
  .swiper-button-next-betting,
  .swiper-button-prev-recom1,
  .swiper-button-next-recom1,
  .swiper-button-prev-recom2,
  .swiper-button-next-recom2,
  .swiper-button-prev-recom3,
  .swiper-button-next-recom3 {
    width: 28px;
    height: 28px;
    background: #dbe9f0;
    border-radius: 50%;
    overflow: visible;
    fill: #6da0dd;
    position: absolute;
    cursor: pointer;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 7px;
    }
  }
  .swiper-button-prev-promo,
  .swiper-button-prev-betting {
    left: -13px;
  }
  .swiper-button-next-promo,
  .swiper-button-next-betting {
    right: -13px;
    img {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
  .swiper-button-prev-recom1,
  .swiper-button-prev-recom2,
  .swiper-button-prev-recom3 {
    left: -13px;
  }
  .swiper-button-next-recom1,
  .swiper-button-next-recom2,
  .swiper-button-next-recom3 {
    right: -13px;
    img {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
}

@media (min-width: 1800px) {
  .right-container {
  }
}
</style>